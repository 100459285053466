import React from "react";
import "../styles/Header.scss";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, setSelectedService } from "../slices/AppSlice";

const Header = () => {
  const dispatch = useDispatch();
  const setActive = (tab) => {
    dispatch(setActiveTab(tab));
  };
  const tab = useSelector((state) => state.app.activeTab);
  const services = useSelector((state) => state.app.services);

  const openService = (e, service, subService) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedService({service, subService}));
    dispatch(setActiveTab("service-details"))
  }
  return (
    <div className="container">
      <div className="header">
        <span className="logo">
          <img alt="" height="80" src={logo} />
        </span>
        <div className="links">
          <ul>
            <li
              onClick={() => setActive("home")}
              className={tab === "home" ? "active" : ""}
            >
              Home
            </li>
            <li
              onClick={() => setActive("services")}
              className={tab === "services" ? "active" : ""}
            >
              Services
              <div className="sub-headers">
                {services.map((service) => (
                  <div className="service" key={service.id} onClick={(e) => openService(e,service.name)}>
                    {service.name}
                    <div className="subServices">
                      {service["sub-services"].map((subService, idx) => <div key={idx} className="subService" onClick={(e) => openService(e,service.name, subService)}>
                        {subService}
                      </div>)}
                    </div>
                  </div>
                ))}
              </div>
            </li>
            <li
              onClick={() => setActive("about")}
              className={tab === "about" ? "active" : ""}
            >
              About Us
            </li>
            <li
              onClick={() => setActive("team")}
              className={tab === "team" ? "active" : ""}
            >
              Our Team
            </li>
            <li
              onClick={() => setActive("contact")}
              className={tab === "contact" ? "active" : ""}
            >
              Contact Us
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
