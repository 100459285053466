import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Team from './components/Team';
import Contact from './components/Contact';
import { useEffect } from 'react';
import { setAbout, setGeneralInfo, setServiceDetails, setServices, setTeam } from './slices/AppSlice';
import ServiceDetails from './components/ServiceDetails';
import {SERVER_BASE_URL} from "./constants/constants.js"

function App() {
  const tab = useSelector(state => state.app.activeTab);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGeneralDetails = async () => {
      try {
        const response = await fetch(`${SERVER_BASE_URL}/api/general`);
        const result = await response.json();
        if(result.type === "success" && result.data) {
          dispatch(setGeneralInfo(result.data[0]));
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await fetch(`${SERVER_BASE_URL}/api/services`);
        const result = await response.json();
        if(result.type === "success" && result.data) {
          dispatch(setServices(result.data));
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchServiceDetails = async () => {
      try {
        const response = await fetch(`${SERVER_BASE_URL}/api/service-details`);
        const result = await response.json();
        if(result.type === "success" && result.data) {
          dispatch(setServiceDetails(result.data));
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchTeam = async () => {
      try {
        const response = await fetch(`${SERVER_BASE_URL}/api/team`);
        const result = await response.json();
        if(result.type === "success" && result.data) {
          dispatch(setTeam(result.data));
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchAbout = async () => {
      try {
        const response = await fetch(`${SERVER_BASE_URL}/api/about`);
        const result = await response.json();
        if(result.type === "success" && result.data) {
          dispatch(setAbout(result.data));
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchServices();
    fetchGeneralDetails();
    fetchTeam();
    fetchAbout();
    fetchServiceDetails();
  },[])

  return (
    <div className="App">
      <div className="header">
        <Header/>
      </div>
      <div className='content'>
        {tab === "home" && <Home/>}
        {tab === "services" && <Services/>}
        {tab === "about" && <About/>}
        {tab === "team" && <Team/>}
        {tab === "contact" && <Contact/>}
        {tab === "service-details" && <ServiceDetails/>}
        <Footer/>
      </div>
    </div>
  );
}

export default App;
