
import { configureStore } from '@reduxjs/toolkit'
import appSlice from './slices/AppSlice'

export const store = configureStore({
  reducer: {
    app: appSlice,
  },
});

export default store;