import React from "react";
import "../styles/Footer.scss";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../slices/AppSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const generalInfo = useSelector((state) => state.app.generalInfo);
  const services = useSelector((state) => state.app.services);
  const openServices = () => {
    dispatch(setActiveTab("services"));
  };
  return (
    <div className="footer">
      <div className="row">
        <div className="colInfo col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h3>{generalInfo.name}</h3>
          <span>
            We provide an opportunity to the healthcare units to benchmark with
            NABH accreditation. It enables the organization in demonstrating a
            commitment to quality care and patient safety thereby ensures the
            best clinical outcomes.
          </span>
        </div>
        <div className="colInfo col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h3>Services</h3>
          <ul onClick={openServices}>
            {services?.map((s) => {
              return <li key={s.id}>{s.name}</li>;
            })}
          </ul>
        </div>
        <div className="colInfo col-lg-4 col-md-4 col-sm-12 col-xs-12 contact-us">
          <h3>Contact Us</h3>
          <span>
            <strong>Corporate Address: </strong>
            {generalInfo.corpAddress}
          </span>
          <span>
            <strong>Registered Address: </strong>
            <a href="https://maps.app.goo.gl/6dpvQ2ooFe9Xw8cG7?g_st=iw" target="_blank">{generalInfo.regAddress}</a>
          </span>
          <span>
            <strong>Contact No: </strong>
            {generalInfo.phone}
          </span>
          <span>
            <strong>Email: </strong>
            {generalInfo.email}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
