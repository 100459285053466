import React, { useEffect } from "react";
import "../styles/About.scss";
import { useSelector } from "react-redux";

const About = () => {
  const about = useSelector((state) => state.app.about);

  return (
    <div className="aboutUsContainer">
      <h1>ABOUT US</h1>
      <div className="aboutUsGrid">
        {about?.map((section, idx) => {
          return (
            <div className="section" id={section.id} key={section.id}>
              {idx % 2 === 0 && (
                <>
                  <div className="image">
                    <img alt="" src={`/assets/about/${section.image}`}></img>
                  </div>
                  <div className="text">
                    <h2>{section.name}</h2>
                    <span>{section.description}</span>
                  </div>
                </>
              )}
              {idx % 2 !== 0 && (
                <>
                  <div className="text">
                    <h2>{section.name}</h2>
                    <span>{section.description}</span>
                  </div>
                  <div className="image">
                    <img alt="" src={`/assets/about/${section.image}`}></img>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default About;
