import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    name: "ARYAL Healthcare Solutions",
    heading: ["Take your hospital business to new height", "Increase your hospital revenue"],
    subHeading: "We collaborate with hospitals and doctors to provide hassle-free claim processing for patients.",
    activeTab: "home",
    services: [],
    serviceDetails: [],
    about: [],
    team: [],
    generalInfo: {},
    selectedService: {}
  },
  reducers: {
    setAppDetails: (state, action) => {
      state = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setServiceDetails: (state, action) => {
      state.serviceDetails = action.payload;
    },
    setAbout: (state, action) => {
      state.about = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    setGeneralInfo: (state, action) => {
      state.generalInfo = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    }
  },
});

export const { setAppDetails, setActiveTab, setServices, setServiceDetails, setAbout, setTeam, setGeneralInfo, setSelectedService } = appSlice.actions;

export default appSlice.reducer;
