import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../styles/ServiceDetails.scss";
import { useSelector } from "react-redux";

const ServiceDetails = () => {
  const service = useSelector(state => state.app.selectedService.service);
  const subService = useSelector(state => state.app.selectedService.subService);
  const serviceDetails = useSelector(state => state.app.serviceDetails);
  const [accordionItems, setAccordionItems] = useState([]);
  const [indices, setIndices] = useState([]);

  useEffect(() => {
    return () => {
      setAccordionItems([]);
    }
  },[])

  useEffect(() => {
    const serviceWiseDetails = groupByService(serviceDetails);
    if(serviceWiseDetails && serviceWiseDetails[service]) {
      const arr = serviceWiseDetails[service];
      setAccordionItems(arr);
      const indices = Array.from({ length: arr.length}, (_, index) => index);
      setIndices(indices);
    } else {
      setAccordionItems([]);
    }
  },[serviceDetails, service, subService]);

  function groupByService(array) {
    return array.reduce((acc, item) => {
      if (!acc[item.service]) {
        acc[item.service] = [];
      }
      acc[item.service].push(item);
      return acc;
    }, {});
  }

  const toggleAccordion = (e) => {
    setIndices(e);
  }
  
  return (
    <div className="serviceDetailsContainer">
      <h1>{service}</h1>
      <div className="serviceDetails">
        <Accordion index={indices} allowMultiple backgroundColor={"#fff"} onChange={toggleAccordion}>
          {accordionItems?.map(accordion => <AccordionItem className="accordion" key={accordion.id}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" className="accordionHeader">
                  {accordion["sub-service"]}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} fontSize={20} className="accordionContent">
              {accordion.content}
            </AccordionPanel>
          </AccordionItem>)}
        </Accordion>
      </div>
    </div>
  );
};

export default ServiceDetails;
