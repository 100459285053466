import React from "react";
import mainImage from "../assets/mainImage.svg";
import "../styles/Home.scss";
import { TypeAnimation } from "react-type-animation";
import { nanoid } from "@reduxjs/toolkit";

const Home = () => {
  const howItWorks = [
    {
      id: nanoid(),
      name: "Share case details",
      description:
        "Our Claim Buddies are here to help you through the process of filing a claim, and all it takes is 5 minutes of your time.",
      image: "share.svg",
    },
    {
      id: nanoid(),
      name: "File claim with professionals",
      description:
        "We can assist you with the determination and the filing of the claim by arranging enough and appropriate documentation.",
      image: "claim.svg",
    },
    {
      id: nanoid(),
      name: "Get paid maximum money",
      description:
        "We guarantee that you always receive the money your insurance company owes you.",
      image: "money.svg",
    },
  ];
  return (
    <>
      <div className="banner">
        <img alt="" src={mainImage} height={500}></img>
        <div className="tagline">
          <TypeAnimation
            className="text"
            sequence={[
              "Increase your hospital revenue now",
              2000,
              "Take your hospital business to new heights",
              2000,
              "We are committed towards quality",
              2000,
            ]}
            speed={50}
            deletionSpeed={75}
            repeat={Infinity}
          />
          <span className="subText">
            We collaborate with hospitals and doctors to provide hassle-free
            claim processing for patients.
          </span>
        </div>
      </div>
      <div className="how-it-works">
        <h2>How it Works?</h2>
        <p>Our team of experienced professionals is dedicated to providing solutions that are tailored to the unique needs of each client.</p>
        <div className="row">
          {howItWorks.map((section) => (
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 info-card" key={section.id}>
              <div className="card">
                <div className="image">
                  <img src={`/assets/how-it-works/${section.image}`} height={200}/>
                </div>
                <div className="heading">{section.name}</div>
                <div className="description">{section.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="partners">
        <h2>Our Clients</h2>
        <div class="grid-container">
          <div class="grid-item">
            <img src="/assets/clients/sjn.jpeg" alt="Shankar Jyoti Nethralaya"/>
            <span>Shankar Jyoti Nethralaya</span>
          </div>
          <div class="grid-item">
            <img src="/assets/clients/tulip.jpeg" alt="Tulip Hospital"/>
            <span>Tulip Hospital</span>
          </div>
          <div class="grid-item">
            <img src="/assets/clients/sanjeevan.jpeg" alt="Sanjeevan Hospitals"/>
            <span>Sanjeevan Hospital, Nawada</span>
          </div>
          {/* <div class="grid-item">
            <img src="/assets/clients/aeham.jpeg" alt="AEHAM Purnea"/>
            <span>AEHAM Hospital</span>
          </div> */}
          <div class="grid-item">
            <img src="/assets/clients/shivalaya.jpeg" alt="Shivalaya Multispeciality Hospital"/>
            <span>Shivalaya Multispeciality Hospital</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
