import React, { useEffect, useState } from "react";
import "../styles/Team.scss";
import maleAvatar from "../assets/male_avatar.svg"
import { useSelector } from "react-redux";

const Team = () => {
  const team = useSelector(state => state.app.team);

  return (
    <div className="teamContainer">
      <h1>THE ARYAL FORCE</h1>
      <div className="teamGrid row">
        {team.map((c) => {
          return (
            <div className="contact col-sm-12 col-md-6 col-lg-6" id={c.id}>
              <div className="content">
                <div className="image">
                  <img alt="" src={c.image ? `/assets/team/${c.image}` : maleAvatar}/>
                </div>
                <div className="details">
                  <span className="name">{c.name}</span>
                  <span className="role">{c.designation}</span>
                  <span className="qualification"><strong>Qualifications: </strong>{c.qualifications}</span>
                  <span className="experience"><strong>Experience: </strong>{c.experience}</span>
                  <span className="expertise"><strong>Expertise: </strong>{c.expertise}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
