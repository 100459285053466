import React, { useEffect } from "react";
import "../styles/Services.scss";
import { useDispatch, useSelector } from "react-redux";

const Services = () => {
  const dispatch = useDispatch();
  const services = useSelector(state => state.app.services);

  return (
    <div className="serviceContainer">
      <h1>OUR SERVICES</h1>
      <div className="serviceGrid row">
        {services.map(service => {
          return <div key={service.id} className="serviceCard">
            <img alt="" src={`/assets/services/${service.image}`} width={200} height={200}/>
            <span className="title">{service.name}</span>
            <span className="description">{service.description}</span>
            {/* <button>More</button> */}
          </div>
        })}
      </div>
    </div>
  );
};

export default Services;
