import React, { useState } from "react";
import "../styles/Contact.scss";
import { MdLocationPin, MdEmail, MdPhone } from "react-icons/md";
import { countries } from "../DropdownUtils";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { IoShareSocial } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {SERVER_BASE_URL} from "../constants/constants.js";

const Contact = () => {
  const bookingDetails = {
    name: "",
    email: "",
    phone: "",
    country: "",
  };

  const [booking, setBooking] = useState(bookingDetails);
  const contactDetails = useSelector((state) => state.app.generalInfo);

  const createBooking = async () => {
    if (!booking.email || !booking.name || !booking.phone) {
      toast.error("Please fill all the details before submitting");
      return;
    }
    try {
      const payload = {
        ...booking,
      };
      const response = await fetch(`${SERVER_BASE_URL}/api/create-booking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (result && result.type === "success") {
        toast.success(
          "Your query is submitted. We will get in touch with you shortly."
        );
        setBooking(bookingDetails);
      }
    } catch (e) {
      toast.error("Something went wrong. Please try again");
    }
  };

  return (
    <div className="contactContainer">
      <ToastContainer />
      <h1>GET IN TOUCH</h1>
      <div className="contactGrid">
        <div className="section">
          <div className="contactInfo">
            <span>
              <MdLocationPin></MdLocationPin>
              Registered Address
            </span>
            <p>
              <a
                href="https://maps.app.goo.gl/6dpvQ2ooFe9Xw8cG7?g_st=iw"
                target="_blank"
              >
                {contactDetails.regAddress}
              </a>
            </p>
            <span>
              <MdLocationPin></MdLocationPin>
              Corporate Address
            </span>
            <p>{contactDetails.corpAddress}</p>
            <span>
              <MdPhone></MdPhone>
              Phone
            </span>
            <p>{contactDetails.phone}</p>
            <span>
              <MdEmail></MdEmail>
              Email
            </span>
            <p>{contactDetails.email}</p>
            <span>
              <IoShareSocial></IoShareSocial>
              Social Media
            </span>
            <div className="social">
              <a
                href={contactDetails.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <PiInstagramLogoFill></PiInstagramLogoFill>
              </a>
              <a
                href={contactDetails.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook></FaFacebook>
              </a>
              <a href={contactDetails.youtube} target="_blank" rel="noreferrer">
                <FaYoutube></FaYoutube>
              </a>
            </div>
          </div>
          <div className="formContainer">
            <div className="form">
              <span>Book a Demo</span>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={booking.name}
                onChange={(e) =>
                  setBooking({ ...booking, name: e.target.value })
                }
              ></input>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={booking.email}
                onChange={(e) =>
                  setBooking({ ...booking, email: e.target.value })
                }
              ></input>
              <input
                type="number"
                maxLength="10"
                name="phone"
                placeholder="Enter your phone"
                value={booking.phone}
                onChange={(e) =>
                  setBooking({ ...booking, phone: e.target.value })
                }
              ></input>
              <select
                name="country"
                value={booking.country}
                onChange={(e) =>
                  setBooking({ ...booking, country: e.target.value })
                }
              >
                <option value="">--Select your Country--</option>
                {countries.map((c) => (
                  <option id={c.code} value={c.code} key={c.code}>
                    {c.name}
                  </option>
                ))}
              </select>
              <button onClick={createBooking}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
